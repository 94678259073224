// import { throttle } from "throttle-debounce";
// import config from '../global.js';

const menuButton = document.getElementById('js-header-menu');
const globalNavigation = document.getElementById('global-nav');
// const sideFixedNav = document.getElementById('js-side-sixed-nav');
// const sideFixedNavButton = document.getElementById('js-side-sixed-nav-button');


const menuButtonFn = () => {

  const toggleMenu = el => {
    el = el.currentTarget;

    if(el.getAttribute('aria-expanded') === 'false') {
      //メニュー開く時
      el.setAttribute('aria-expanded', 'true');
      el.setAttribute('aria-label', 'メニューを閉じる');
      globalNavigation.setAttribute('aria-hidden', 'false');
    } else {
      //メニュー閉じる時
      el.setAttribute('aria-expanded', 'false');
      el.setAttribute('aria-label', 'メニューを開く');
      globalNavigation.setAttribute('aria-hidden', 'true');
    }
  };

  menuButton.addEventListener('click', toggleMenu);

  const mediaQuery = window.matchMedia(`(min-width: ${769 / 16}em)`);

  const handleTabletChange = mq => {
    if(mq.matches) {
      menuButton.setAttribute('aria-expanded', 'true');
      menuButton.setAttribute('aria-label', 'メニューを閉じる');
      globalNavigation.setAttribute('aria-hidden', 'false');
    } else {
      menuButton.setAttribute('aria-expanded', 'false');
      menuButton.setAttribute('aria-label', 'メニューを開く');
      globalNavigation.setAttribute('aria-hidden', 'true');
    }
  };

  mediaQuery.addEventListener('change', handleTabletChange);

  handleTabletChange(mediaQuery);
};

const scrolInFixedNav = () => {
  const fixedNav = document.getElementById('js-fixed-nav');
  const target = document.querySelector('.l-main');

  const intersection = entries => {
    entries.forEach(entry => {
      if(entry.isIntersecting){
        // console.log('enter');
        fixedNav.classList.add('is-scroll-in');
      }else{
        fixedNav.classList.remove('is-scroll-in');
        // console.log('not enter');
      }
    });
  };

  const option = {
    root: null,
    rootMargin: `0px 0px -100% 0px`,
    threshold:0,
  };

  const observer = new IntersectionObserver(intersection, option);

  observer.observe(target);


  // const scrollFunction = () => {
  //   if(window.innerWidth >= 768) {
  //     return;
  //   }
  //   const scroll = window.pageYOffset || document.documentElement.scrollTop;

  //   if (scroll > 150) {
  //     fixedNav.classList.add('is-scroll-in');
  //   } else {
  //     fixedNav.classList.remove('is-scroll-in');
  //   }
  // };

  // scrollFunction();

  // const throttleFunction = throttle(250 ,() => {
  //   scrollFunction();
  // });

  // window.addEventListener('scroll', throttleFunction);

};

export default () => {
  menuButtonFn();
  scrolInFixedNav();
};
