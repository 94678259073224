import Swiper from 'swiper/bundle';

const dotSlider = () => {
  const sliderList = document.querySelectorAll('.js-dot-slider');

  if(sliderList.length === 0) {
    return;
  }

  const mediaQuery = window.matchMedia(`(min-width: ${769 / 16}em)`);



  sliderList.forEach(slider => {

    const sliderName = slider.getAttribute('data-slide-name');

    let swiper;

    const createSwiper = () => {
      swiper = new Swiper(slider, { // eslint-disable-line
        slidesPerView: 'auto',
        spaceBetween: 20,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        a11y: {
          containerMessage:sliderName,
          prevSlideMessage: '前のスライドへ',
          nextSlideMessage: '次のスライドへ',
          slideLabelMessage:'{{index}}枚目のスライド',
          paginationBulletMessage: '{{index}}枚目のスライドを表示',
        },

        breakpoints: {
          769: {
            spaceBetween: 25,
          }
        },

        on: {
          init: () => {

          },
        },
      });
    };

    createSwiper();

    const handle = mq => {
      if(slider.classList.contains('js-dot-slider--sp-only')) {
        if (mq.matches && swiper) {
          swiper.destroy(false,true);
          swiper = undefined;
        } else {
          if(!swiper) {
            createSwiper();
          }
        }
      }
    };

    handle(mediaQuery);

    mediaQuery.addEventListener('change', handle);

  });

};

const arrowSlider = () => {
  const sliderList = document.querySelectorAll('.js-arrwo-slider');

  if(sliderList.length === 0) {
    return;
  }

  sliderList.forEach(slider => {

    const sliderName = slider.getAttribute('data-slide-name');

    let swiper; // eslint-disable-line

    const createSwiper = () => {
      swiper = new Swiper(slider, { // eslint-disable-line
        loop:true,
        slidesPerView: 'auto',
        spaceBetween: 20,

        navigation: {
          nextEl: '.js-arrwo-slider__next',
          prevEl: '.js-arrwo-slider__prev',
        },

        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        a11y: {
          containerMessage:sliderName,
          prevSlideMessage: '前のスライドへ',
          nextSlideMessage: '次のスライドへ',
          slideLabelMessage:'{{index}}枚目のスライド',
          paginationBulletMessage: '{{index}}枚目のスライドを表示',
        },

        breakpoints: {
          769: {
            spaceBetween: 25,
          }
        },
      });
    };

    createSwiper();

  });
};

export default () => {
  dotSlider();
  arrowSlider();
};
