const init = () => {
  const files = document.querySelectorAll('.js-file-area');

  if(files.length === 0) {
    return;
  }

  files.forEach(file => {
    const input = file.querySelector('input');
    const button = file.querySelector('button');

    button.addEventListener('click',() => {
      input.click();
    });

    button.addEventListener("keydown", (event) => {
      if (!button.isEqualNode(event.target)) {
        return;
      }

      console.log(event.code);

      if (event.code === 'Space' || event.code === 'Enter') {
        event.preventDefault();
        input.click();
      }
    });

    input.addEventListener('change', event => {
      let fileName = event.target.files[0].name;

      const maxLength = 25;

      if(fileName.length > maxLength) {
        fileName = `${fileName.slice(0, maxLength)}…`;
      }

      button.textContent = fileName;
    });
  });
};

export default init;
