import headerModules from "./modules/headerModules";
import sliderModules from "./modules/sliderModules";
import inputFile from "./modules/inputFile";

document.addEventListener('DOMContentLoaded', () => {
  headerModules();
  sliderModules();
  inputFile();
});

window.addEventListener('load', ()=> {
});
